import React from "react";
import "./CalcLay.css";
import NavBar from "../NavBar/NavBar.tsx";
import Calculator from "../Calculator.tsx";
import { Link } from 'react-router-dom'






const CalcLay: React.FC = ({ popup }) => {
    return (
        <div className="calc-lay" style={{ backgroundImage: 'url("img/CalcLay/vibrant-red-truck-with-container-against-industrial-background 1@2x.png")' }}>
            <NavBar popup={popup}/>
            <span className="calc-lay-title">Доставка и выкуп товаров <br></br>из Китая, Южной Кореи и <br></br>других стран Азии</span>
            <div className="calc-btns-wrap">
                <span style={{cursor: 'pointer'}} id="services"><a href="#transportation" style={{color: 'black'}}>Услуги</a></span>
                <span style={{cursor: 'pointer'}} id="consultation" onClick={() => window.location.href='https://t.me/ulamanager'}>Консультация</span>
            </div>
            
            <span className="calc-lay-desc">Для точного расчета стоимости доставки воспользуйтесь нашим калькулятором</span>
            {/* <div className="calc-panel">
                <h2>Перезвоните мне</h2>
                    <span>Мы свяжемся с вами в течении 10 минут для <br></br>уточнения всех деталей.</span>
                    <form action="/" method="post" id="bitrix_form">
                        <input id="client_name" name="client_name" placeholder="Ваше Имя"required />
                        <hr></hr>

                        <input id="client_phone" placeholder="+7 (999) 999-00-00" name="client_phone" required />
                        <hr></hr>

                        <input id="client_email" placeholder="abc@mail.ru" name="client_email" required />
                        <hr></hr>

                        <textarea id="comment" name="comment" placeholder="Комментарий" rows={4} required></textarea>
                        <hr></hr>
                        <button type="submit">Перезвоните мне <img src="\img\Union.svg" alt="" /></button>
                    </form>
            </div> */}

                <Calculator/>
            {/* <div className="calc-machine-lay">
                <div className="calc-machine-way-wrap">
                    <div className="calc-machine-way-item">
                        <img src="/img/CalcLay/Pin_fill.png"/>
                        <select name="" id="">
                            <optgroup >
                                <option value="Китай">Китай</option>
                            </optgroup>
                        </select>
                    </div>

                    <div className="calc-machine-way-item">
                        <img style={{marginLeft: '10px'}} src="\img\CalcLay\Vector 399.png"/>
                        <hr />
                    </div>

                    <div className="calc-machine-way-item">
                        <img src="\img\CalcLay\иконка_заглушка.png"/>
                        <select name="" id="">
                            <option value="Россия">Россия</option>
                        </select>
                    </div>

                    <img src="\img\CalcLay\иконка_кнопка.png" className="calc-change-btn" />
                </div>
                <div className="calc-machine">
                    <div className="calc-deliviry-type">
                        <div className="calc-deliviry-type-item-act">
                            <img src="\img\CalcLay\free-icon-truck-11223874.svg" alt="" />
                            <span>Авто</span>
                        </div>
                        <div className="calc-deliviry-type-item">
                            <img src="\img\CalcLay\avia.svg" alt="" />
                            <span>Авиа</span>
                        </div>
                        <div className="calc-deliviry-type-item">
                            <img src="\img\CalcLay\train.svg" alt="" />
                            <span>ЖД</span>
                        </div>
                        <div className="calc-deliviry-type-item">
                            <img src="\img\CalcLay\ship.svg" alt="" />
                            <span>Морем</span>
                        </div>
                    </div>

                    <div className="calc-parapms-layout">
                        <div className="calc-params-wrap">
                            <div className="calc-param-wrap">
                                <div className="calc-param-values-wrap">
                                    <div className="calc-param-values-wrap-names">
                                        <span style={{color: '#626C75'}}>Вес</span>
                                        <span style={{color: '#626C75'}}>кг.</span>
                                    </div>
                                    <div className="calc-param-values-wrap-names">
                                        <span style={{color: 'black', fontSize: '20px', lineHeight: '22px'}}>10 000 000.00</span>
                                    </div>
                                </div>
                                <img src="\img\CalcLay\Vector 507.png" alt="" />
                                <div className="calc-params-btns">
                                    <img src="\img\CalcLay\Expand_up.png" alt="" />
                                    <img src="\img\CalcLay\expand-down.png" alt="" />
                                </div>
                            </div>
                            <div className="calc-param-wrap">
                                <div className="calc-param-values-wrap">
                                    <div className="calc-param-values-wrap-names">
                                        <span style={{color: '#626C75'}}>Объем</span>
                                        <span style={{color: '#626C75'}}>куб.м.</span>
                                    </div>
                                    <div className="calc-param-values-wrap-names">
                                        <span style={{color: 'black', fontSize: '20px', lineHeight: '22px'}}>13</span>
                                    </div>
                                </div>
                                <img src="\img\CalcLay\Vector 507.png" alt="" />
                                <div className="calc-params-btns">
                                    <img src="\img\CalcLay\Expand_up.png" alt="" />
                                    <img src="\img\CalcLay\expand-down.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="calc-parap-select">
                            <select name="" id="">Выберите из иписка</select>
                        </div>
                    </div>
                    <div className="calc-results">
                        <div className="calc-result-text-wrap">
                            <div className="calc-deliviry-result">
                                <div className="calc-deliviry-result-title">Сроки доставки</div>
                                <div className="calc-deliviry-result-value">
                                    <div>10-24</div>
                                    <span>дн.</span>
                                </div>
                            </div>

                            <div className="calc-deliviry-result">
                                <div className="calc-deliviry-result-title">Стоимость</div>
                                <div className="calc-deliviry-result-value">
                                    <span>от</span>
                                    <div>$9999</div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="calc-next-action-btn">
                            <span>Продолжить просчет</span>
                            <img src="\img\CalcLay\calc-next.png" alt="" />
                        </div>
                        
                    </div>
                    
                </div>
            </div> */}
        </div>
    );
}

export default CalcLay;
