import React from "react";
import "./FAQ.css";

const FAQ: React.FC = () => {
    function toggleAnswer(element: HTMLElement): void {
        const answer = element.parentElement?.nextElementSibling as HTMLElement;
        if (answer) {
            answer.style.display = answer.style.display === 'block' ? 'none' : 'block';
        }
    }

    return (
        <>
            <div className="container">
                <div className="faq">
                    <h2>Популярные вопросы <br />и ответы</h2>
                    {/* <div className="question">
                        <div className="question-text">Доставляете ли вы карго?</div>
                        <div className="toggle" onClick={(e) => toggleAnswer(e.currentTarget)}>+</div>
                    </div> */}
                    <div className="answer" style={{ display: 'none' }}>Да, мы можем доставить груз без таможенного оформления </div>
                    <hr />

                    <div className="question">
                        <div className="question-text">Как я могу быть уверен, что получу свой товар?</div>
                        <div className="toggle" onClick={(e) => toggleAnswer(e.currentTarget)}>+</div>
                    </div>
                    <div className="answer" style={{ display: 'none' }}>Наша компания дает полную гарантию и страхует ваши грузы на 100% стоимости, мы заключаем с вами договор, а оплата груза происходит только при получении в России </div>
                    <hr />

                    <div className="question">
                        <div className="question-text">Что делать, если придет брак?</div>
                        <div className="toggle" onClick={(e) => toggleAnswer(e.currentTarget)}>+</div>
                    </div>
                    <div className="answer" style={{ display: 'none' }}>Брак прийти вам не может, мы несколько раз перепроверяем ваши грузы у себя на складе в Китае перед отправкой в РФ</div>
                    <hr />

                    <div className="question">
                        <div className="question-text">Сколько занимает доставка?</div>
                        <div className="toggle" onClick={(e) => toggleAnswer(e.currentTarget)}>+</div>
                    </div>
                    <div className="answer" style={{ display: 'none' }}>Авиаперевозки до 7 дней<br></br>
                                                                        Автоперевозки 10-30 дней <br></br>
                                                                        ЖД перевозки 35-45 дней <br></br>
                                                                        Морские перевозки 35-45 дней </div>
                    <hr />

                    <div className="question">
                        <div className="question-text">Что делать, если вы привезли товар не в срок?</div>
                        <div className="toggle" onClick={(e) => toggleAnswer(e.currentTarget)}>+</div>
                    </div>
                    <div className="answer" style={{ display: 'none' }}>Мы стараемся всегда следовать условиям договора, однако, в случае задержки мы выплачиваем компенсацию </div>
                    <hr />

                    <div className="question">
                        <div className="question-text">Какие товары вы возите?</div>
                        <div className="toggle" onClick={(e) => toggleAnswer(e.currentTarget)}>+</div>
                    </div>
                    <div className="answer" style={{ display: 'none' }}>Наша компания занимается перевозкой всех разрешенных товаров, в том числе скоропортящиеся продукты и негабаритные товары</div>
                    <hr />

                    <div className="question">
                        <div className="question-text">Как узнать, что поставщик надежный?</div>
                        <div className="toggle" onClick={(e) => toggleAnswer(e.currentTarget)}>+</div>
                    </div>
                    <div className="answer" style={{ display: 'none' }}>Мы писали, как сделать это самостоятельно в статье, но если вы сотрудничаете с партнерами из нашего списка проверенных поставщиков, то мы гарантируем их надежность</div>
                    <hr />

                    <div className="question">
                        <div className="question-text">Что делать если моему товару нужна особая упаковка?</div>
                        <div className="toggle" onClick={(e) => toggleAnswer(e.currentTarget)}>+</div>
                    </div>
                    <div className="answer" style={{ display: 'none' }}>Напишите нам и мы упакуем в соответствии с вашим ТЗ</div>
                    <hr />

                    
                </div>

                <div className="feedback">
                    <h2>Перезвоните мне</h2>
                    <span>Мы свяжемся с вами в течении 10 минут для <br></br>уточнения всех деталей.</span>
                    <form action="/" method="post" id="bitrix_form">
                        <input id="client_name" name="client_name" placeholder="Ваше Имя"required />
                        <hr></hr>

                        <input id="client_phone" placeholder="+7 (999) 999-00-00" name="client_phone" required />
                        <hr></hr>

                        <input id="client_email" placeholder="abc@mail.ru" name="client_email" required />
                        <hr></hr>

                        <textarea id="comment" name="comment" placeholder="Комментарий" rows={4} required></textarea>
                        <hr></hr>
                        <button type="submit">Перезвоните мне <img src="\img\Union.svg" alt="" /></button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default FAQ;
