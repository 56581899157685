import React from "react";
import "./CalcPage.css";
import NavBar from "../NavBar/NavBar.tsx";

const CalcPage: React.FC = () => {

    return (
        <div style={{background: '#EFF0F2', minHeight: '100vh'}} className="App">
            <NavBar/>
            <div className="main-calc-lay-wrap">
                <div className="main-calc-steps">

                </div>

                <div className="main-calc-layout">
                    <div className="main-calc-info" style={{ backgroundImage: 'url("img/CalcLay/calc-ula.png")' }}> 
                        <span className="main-calc-info-title">Маршрут<br></br>и параметры</span>
                        <span className="main-calc-info-desc">Укажите пункты отправления и назначения, а также вес и объём доставляемого груза</span>
                    </div>

                    <div className="main-calc-params">
                        <span className="main-calc-title-type-1">Маршрут</span>

                        <div className="main-calc-machine-way-wrap">
                            <div className="main-calc-machine-way-item" >
                                <img src="/img/CalcLay/Pin_fill.png"/>
                                <span>Китай</span>
                            </div>

                            <div className="main-calc-machine-way-item">
                                <img style={{marginLeft: '10px'}} src="\img\CalcLay\Vector 399.png"/>
                                <hr />
                            </div>

                            <div className="main-calc-machine-way-item">
                                <img src="\img\CalcLay\иконка_заглушка.png"/>
                                <span>Россия</span>
                            </div>

                            <img src="\img\CalcLay\иконка_кнопка.png" className="main-calc-change-btn"/>

                        </div>


                        <span className="main-calc-title-type-2">Способ</span>

                        <div className="main-calc-deliviry-type">
                            <div className="main-calc-deliviry-type-item-act">
                                <img src="/img/CalcLay/auto-act.svg"/>
                                <span>Авто</span>
                            </div>
                            
                            <div className="main-calc-deliviry-type-item">
                                <img src="/img/CalcLay/avia-def.svg"/>
                                <span>Авиа</span>
                            </div>
                            <div className="main-calc-deliviry-type-item">
                                <img src="/img/CalcLay/train-def.svg"/>
                                <span>ЖД</span>
                            </div>
                            
                        </div>


                        <span className="main-calc-title-type-1">Параметры груза</span>
                        <span className="main-calc-title-type-2">Варианты расчета</span>

                        <div className="main-calc-select-row">
                            <div className="radio-button">
                                <input type="radio" id="radio1" name="radio-group"/>
                                <label htmlFor="radio1">
                                    <span className="marker">
                                    <span className="checkmark"></span>
                                    </span>
                                    кг/груз
                                </label>
                            </div>

                            <div className="radio-button">
                                <input type="radio" id="radio2" name="radio-group"/>
                                <label htmlFor="radio2">
                                    <span className="marker">
                                    <span className="checkmark"></span>
                                    </span>
                                    кг/упаковка
                                </label>
                            </div>
                        </div>

                        <div className="main-calc-select-column" style={{justifyContent: 'space-between', marginTop: '2%'}}>
                            <div className="calc-param-wrap">
                                    <div className="calc-param-values-wrap">
                                        <div className="calc-param-values-wrap-names">
                                            <span style={{color: '#626C75'}}>Вес</span>
                                            <span style={{color: '#626C75'}}>кг.</span>
                                        </div>
                                        <div className="calc-param-values-wrap-names">
                                        <input 
                                            className="calc-param-values-wrap-names-text" 
                                            type="number" // Add type="number" for numeric input
                                            
                                        />
                                        </div>
                                    </div>
                                    <img src="\img\CalcLay\Vector 507.png" className="calc-divider-line" alt="" />
                                    <div className="calc-params-btns">
                                        <img src="\img\CalcLay\Expand_up.png" alt=""/>
                                        <img src="\img\CalcLay\expand-down.png" alt=""/>
                                    </div>
                                </div>

                                <div className="calc-param-wrap">
                                    <div className="calc-param-values-wrap">
                                        <div className="calc-param-values-wrap-names">
                                            <span style={{color: '#626C75'}}>Вес</span>
                                            <span style={{color: '#626C75'}}>кг.</span>
                                        </div>
                                        <div className="calc-param-values-wrap-names">
                                        <input 
                                            className="calc-param-values-wrap-names-text" 
                                            type="number" // Add type="number" for numeric input
                                            
                                        />
                                        </div>
                                    </div>
                                    <img src="\img\CalcLay\Vector 507.png" className="calc-divider-line" alt="" />
                                    <div className="calc-params-btns">
                                        <img src="\img\CalcLay\Expand_up.png" alt=""/>
                                        <img src="\img\CalcLay\expand-down.png" alt=""/>
                                    </div>
                                </div>
                        </div>

                        <div className="main-calc-cargo-paraps-wrap">
                            <div className="main-calc-select-column">
                            <span className="main-calc-title-type-2">Еденицы измерения</span>

                                <div className="radio-button">
                                    <input type="radio" id="radio2" name="radio-group"/>
                                    <label htmlFor="radio2">
                                        <span className="marker">
                                        <span className="checkmark"></span>
                                        </span>
                                        Миллиметры
                                    </label>
                                </div>

                                <div className="radio-button">
                                    <input type="radio" id="radio2" name="radio-group"/>
                                    <label htmlFor="radio2">
                                        <span className="marker">
                                        <span className="checkmark"></span>
                                        </span>
                                        Сантиметры
                                    </label>
                                </div>

                                <div className="radio-button">
                                    <input type="radio" id="radio2" name="radio-group"/>
                                    <label htmlFor="radio2">
                                        <span className="marker">
                                        <span className="checkmark"></span>
                                        </span>
                                        Метры
                                    </label>
                                </div>
                            </div>
                            <img src="img\CalcLay\Box.png" alt="" />
                        </div>

                        <span className="main-calc-title-type-2">Параметры упаковок</span>

                        <div className="main-calc-select-row">
                            <div className="radio-button">
                                <input type="radio" id="radio1" name="radio-group"/>
                                <label htmlFor="radio1">
                                    <span className="marker">
                                    <span className="checkmark"></span>
                                    </span>
                                    Одинаковые
                                </label>
                            </div>

                            <div className="radio-button">
                                <input type="radio" id="radio2" name="radio-group"/>
                                <label htmlFor="radio2">
                                    <span className="marker">
                                    <span className="checkmark"></span>
                                    </span>
                                    Разные
                                </label>
                            </div>
                        </div>


                        <div className="main-calc-results">
                            <div className="main-calc-result-text-wrap">
                                <div className="main-calc-deliviry-result">
                                    <div className="main-calc-deliviry-result-title">Сроки доставки</div>
                                    <div className="main-calc-deliviry-result-value">
                                        <div>-</div>
                                        <span>дн.</span>
                                    </div>
                                </div>

                                <div className="main-calc-deliviry-result">
                                    <div className="main-calc-deliviry-result-title">Стоимость</div>
                                    <div className="main-calc-deliviry-result-value">
                                        <span>от</span>
                                        <div>-</div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="main-calc-next-action-btn">
                                <span>Продолжить просчет</span>
                                <img src="\img\CalcLay\calc-next.png" alt="" />
                            </div>
                            
                        </div>
                        
                </div>
            </div>
        </div>
    </div>
        
    );
}

export default CalcPage;
