import React from "react";
import { Link } from "react-router-dom";
import './Blog.css'

const Blog: React.FC = () => {
    

    return (
        <>
            <div id="blog" className="blog-wrap">
                <div className="blog-header">
                    <span className="blog-title">Полезные статьи из нашего блога</span>
                    <div className="blog-header-btn-wrap">
                        <span>Смотреть все</span>
                        <img/>
                    </div>
                </div>

                <div className="blog-slider-wrap">
                        <div className="blog-slider-item-wrap" onClick={() => window.location.href='https://vc.ru/1687420'}>
                            <div className="blog-slider-item" style={{backgroundImage: 'url("img/Blog/Обложка статьи 1.png")'}}>
                                <img src="img\Blog\Union-w.png" alt="" />
                                <div className="blog-slider-item-text-wrap">
                                    <span className="blog-slider-item-task">Поиск и проверка поставщика</span>
                                    <span className="blog-slider-item-time">24 окт. 2024</span>
                                </div>
                            </div>
                            <span className="blog-slider-item-title">Хочу заказать товары из Китая, но не знаю, как найти и проверить поставщика?</span>
                        </div>

                        <div className="blog-slider-item-wrap" onClick={() => window.location.href='https://vc.ru/marketplace/1611056-dostavka-iz-kitaya-dorogo-rasskazyvaem-kakie-est-sposoby-sekonomit-na-nei'}>
                            <div className="blog-slider-item" style={{backgroundImage: 'url("img/Blog/Обложка статьи 2.png")'}}>
                                <img src="img\Blog\Union-w.png" alt="" />
                                <div className="blog-slider-item-text-wrap">
                                    <span className="blog-slider-item-task">Поставки в Китай</span>
                                    <span className="blog-slider-item-time">25 окт. 2024</span>
                                </div>
                            </div>
                            <span className="blog-slider-item-title">Как сэкономить на поставке из Китая</span>
                        </div>



                        <div className="blog-slider-item-wrap" onClick={() => window.location.href='https://vc.ru/1670819'}>
                            <div className="blog-slider-item" style={{backgroundImage: 'url("img/Blog/Обложка статьи 3.png")'}}>
                                <img src="img\Blog\Union-w.png" alt="" />
                                <div className="blog-slider-item-text-wrap">
                                    <span className="blog-slider-item-task">Как сейчас оплачивать поставки в Китай?</span>
                                    <span className="blog-slider-item-time">16 окт. 2024</span>
                                </div>
                            </div>
                            <span className="blog-slider-item-title">Как сейчас оплачивать поставки в Китай?</span>
                        </div>
                    
                    </div>
            </div>
        </>
    );
}

export default Blog;