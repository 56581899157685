import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'; 
import CalcPage from './components/calculator/CalcPage/CalcPage.tsx';
import AboutUs from "./components/calculator/AbotUs/AboutUs.tsx";
import CalcLay from "./components/calculator/CalcLay/CalcLay.tsx";
import Calculator from "./components/calculator/Calculator.tsx";
import FAQ from "./components/calculator/FAQ/FAQ.tsx";
import Indastrias from "./components/calculator/Industrias/Indastrias.tsx";
import NavBar from './components/calculator/NavBar/NavBar.tsx';
import Footer from "./components/Footer/Footer.tsx";
import WhyWe from "./components/WhyWe/WhyWe.tsx";
import Team from "./components/calculator/Team/Team.tsx";
import Transportation from "./components/calculator/Transportation/Transportation.tsx";
import Blog from "./components/calculator/Blog/Blog.tsx";
import { useState } from "react";

const Links = () => {
  const [isOpen, setIsOpen] = useState(true); 

  return (
    <div 
      id="link-container" 
      style={{
        position: 'fixed', 
        right: '2%', 
        bottom: '1%', 
        zIndex: '5', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center',
        opacity: isOpen ? 1 : 0, 
        transition: 'opacity 0.3s ease-in-out', 
      }}
    >
      {isOpen && ( 
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <a href="https://vk.com/ulalogistics24" target="_blank" rel="noopener noreferrer"> 
            <img style={{width: '50px', marginBottom: '15px'}} src="./img/vkRed.png" alt="VK"/>
          </a>
          <a href="https://t.me/ulalogistics24" target="_blank" rel="noopener noreferrer">
            <img style={{width: '50px', marginBottom: '15px'}} src="./img/tgRed.png" alt="Telegram"/>
          </a>
          <a href="https://wa.me/message/N3QMZVAYPPMHM1" target="_blank" rel="noopener noreferrer">
            <img style={{width: '50px', marginBottom: '15px'}} src="./img/waRed.png" alt="WhatsApp"/>
          </a>
        </div>
      )}
      <img 
        style={{width: '70px'}} 
        src="./img/closeRed.png" 
        alt="Close" 
        onClick={() => setIsOpen(false)} 
      /> 
    </div>
  );
};

const PopUp: React.FC = ({ popup }) => {
  return(
      <div className="popup-layout">
          <div className="popup-wrap">
              <span className="popup-title">Перезвоните мне</span>
              <span className="popup-desc">Мы свяжемся с вами в течении 10 минут<br></br>для уточнения всех деталей.</span>
              <form action="/" method="post" id="bitrix_form">
                  <div className="popup-input-wrap">
                      <span className="popup-input-title">Ваше имя</span>
                      <input className="popup-input" placeholder='Иван' id="client_name" name="client_name"required />
                  </div>

                  <div className="popup-input-wrap">
                      <span className="popup-input-title">Номер телефона</span>
                      <input className="popup-input" id="client_phone" placeholder="+7 (999) 999-00-00" name="client_phone" required />
                  </div>

                  <button onClick={() => popup(false)} style={{cursor: 'pointer'}} className="popap-action-btn" type="submit">
                            <span>Перезвоните мне</span>
                            <img src="\img\CalcLay\calc-next.png" alt="" />
                  </button>
              </form>
          </div>
      </div>
  )
}


const Main = () => {
  const [popup, setPopup] = useState(false)
  return (
    <div className='App'>
      {popup && <PopUp popup={setPopup}/> }
      <CalcLay popup={setPopup}/>
      <AboutUs/>
      <Indastrias/>
      <Transportation/>
      <WhyWe/>
      {/* <Team/> */}
      <Blog/>
      <FAQ/>
      <Footer/>
      <Links /> 
    </div>
  );
}

function App() {
  return (
    <BrowserRouter> 
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/calculator" element={<CalcPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App; 
