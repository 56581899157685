import {useEffect, useState} from "react";
import './Calculator.css'

const Modal_from: React.FC = ({ Close, Country }) => {
    const [City, setCity] = useState('Москва');
    const [actFilt, setActFilt] = useState('all');
    useEffect(() => {
        Country(City); 
    }, [City]);


    const ways = {
        'Iu': {
            'name': 'Иу',
            'group': 'china',
        },
        'Guanchzhow': {
            'name': 'Гуанчжоу',
            'group': 'china',
        }
    };

    const uniqueGroups = [...new Set(Object.values(ways).map(item => item.group))];

    return (
        <div className="modal-wrap">
            <div className="modal-header">
                <img src="/img/CalcLay/Pin_fill.png" alt="" />
                <span>Откуда</span>
                <img src="img\CalcLay\Close_square.png" alt="" style={{cursor: 'pointer'}} onClick={() => Close(false)} />
            </div>

            <div className="modal-filter">
                <span className={actFilt === 'all' ? "calc-filt-item-act" : "calc-filt-item"} onClick={() => setActFilt('all')}>Все</span>
                {uniqueGroups.map((group, index) => (
                    <span 
                        style={{cursor: 'pointer'}}
                        key={index}
                        className={actFilt === group ? "calc-filt-item-act" : "calc-filt-item"} 
                        onClick={() => setActFilt(group)}
                    >
                        {group === 'russia' ? 'Россия' : 
                         group === 'china' ? 'Китай' : 
                         group} 
                    </span>
                ))}
            </div>

            <div className="calc-cities-wrap">
                {Object.keys(ways).map((city, index) => {
                    const shouldShow = actFilt === 'all' || ways[city].group === actFilt; 
                    return (
                        shouldShow && (
                            <div className="city-item" style={{cursor: 'pointer'}} key={index} onClick={() => setCity(ways[city].name)}> 
                                <input 
                                    type="radio" 
                                    id={`city${index + 1}`} 
                                    name="city" 
                                    value={city} 
                                />
                                <label htmlFor={`city${index + 1}`} className="city-label">
                                    <span className="city-marker"></span>
                                    {ways[city].name} 
                                </label>
                            </div>
                        )
                    );
                })}
            </div>

        </div>
    );
};



const Modal_to: React.FC = ({ Close, Country }) => {
    const [City, setCity] = useState('Москва');
    const [actFilt, setActFilt] = useState('all');
    useEffect(() => {
        Country(City); 
        () => Close;
    }, [City]);


    const ways = {
        'Moscow': {
            'name': 'Москва',
            'group': 'russia',
        }
    };

    const uniqueGroups = [...new Set(Object.values(ways).map(item => item.group))];

    return (
        <div className="modal-wrap">
            <div className="modal-header">
                <img src="/img/CalcLay/Pin_fill.png" alt="" />
                <span>Куда</span>
                <img src="img\CalcLay\Close_square.png" alt="" style={{cursor: 'pointer'}} onClick={() => Close(false)} />
            </div>

            <div className="modal-filter">
                <span className={actFilt === 'all' ? "calc-filt-item-act" : "calc-filt-item"} onClick={() => setActFilt('all')}>Все</span>
                {uniqueGroups.map((group, index) => (
                    <span 
                        style={{cursor: 'pointer'}}
                        key={index}
                        className={actFilt === group ? "calc-filt-item-act" : "calc-filt-item"} 
                        onClick={() => setActFilt(group)}
                    >
                        {group === 'russia' ? 'Россия' : 
                         group === 'china' ? 'Китай' : 
                         group} 
                    </span>
                ))}
            </div>

            <div className="calc-cities-wrap">
                {Object.keys(ways).map((city, index) => {
                    const shouldShow = actFilt === 'all' || ways[city].group === actFilt; 
                    return (
                        shouldShow && (
                            <div className="city-item" style={{cursor: 'pointer'}} key={index} onClick={() => setCity(ways[city].name)}> 
                                <input 
                                    type="radio" 
                                    id={`city${index + 1}`} 
                                    name="city" 
                                    value={city} 
                                />
                                <label htmlFor={`city${index + 1}`} className="city-label">
                                    <span className="city-marker"></span>
                                    {ways[city].name} 
                                </label>
                            </div>
                        )
                    );
                })}
            </div>

        </div>
    );
}

const Type: React.FC = ({ Close, Type }) => {
    const [type, setType] = useState(null);

    useEffect(() => {
        Type(type); 
    }, [type]);

    return (
        <div style={{ top: '50%' }} className="modal-wrap">
            <div className="modal-header">
                <img src="/img/CalcLay/Pin_fill.png" alt="" />
                <span>Выберите тип</span>
                <img src="img\CalcLay\Close_square.png" alt="" style={{cursor: 'pointer'}} onClick={() => Close(false)} />
            </div>

            <div className="calc-cities-wrap">
                <div className="city-item" onClick={() => setType('clothes')} style={{cursor: 'pointer'}}>
                    <input type="radio" id="clothes" name="type" value="clothes" checked={type === 'clothes'} onChange={() => setType('clothes')} /> 
                    <label htmlFor="clothes" className="city-label">
                        <span className="city-marker"></span>
                        Одежда
                    </label>
                </div>
                <div className="city-item" onClick={() => setType('shoes')} style={{cursor: 'pointer'}}>
                    <input type="radio" id="shoes" name="type" value="shoes" checked={type === 'shoes'} onChange={() => setType('shoes')} />
                    <label htmlFor="shoes" className="city-label">
                        <span className="city-marker"></span>
                        Обувь
                    </label>
                </div>
                <div className="city-item" onClick={() => setType('consumer')} style={{cursor: 'pointer'}}>
                    <input type="radio" id="consumer" name="type" value="consumer" checked={type === 'consumer'} onChange={() => setType('consumer')} />
                    <label htmlFor="consumer" className="city-label">
                        <span className="city-marker"></span>
                        Товары народного потребления
                    </label>
                </div>
                <div className="city-item" onClick={() => setType('autoparts')} style={{cursor: 'pointer'}}>
                    <input type="radio" id="autoparts" name="type" value="autoparts" checked={type === 'autoparts'} onChange={() => setType('autoparts')} />
                    <label htmlFor="autoparts" className="city-label">
                        <span className="city-marker"></span>
                        Автозапчасти
                    </label>
                </div>
                <div className="city-item" onClick={() => setType('household')} style={{cursor: 'pointer'}}>
                    <input type="radio" id="household" name="type" value="household" checked={type === 'household'} onChange={() => setType('household')} /> 
                    <label htmlFor="household" className="city-label">
                        <span className="city-marker"></span>
                        Хоз товары
                    </label>
                </div>
            </div>

        </div>
    );
};



const Calculator: React.FC = () => {
    const [weight, setWeight]  = useState(1000)
    const [volume, setVolume] = useState(13)
    const [countryFrom, setCountryFrom] = useState('Россия')
    const [countryTo, setCountryTo] = useState('Китай')
    const [productType, setProductType] = useState(null)
    const [time, setTime] = useState('-')
    const [cost, setCost] = useState(0)
    const [IsModalFromOpened, setIsModalFromOpened] = useState(false)
    const [IsModalToOpened, setIsModalToOpened] = useState(false)
    const [IsModalTypeOpened, setIsModalTypeOpened] = useState(false)
    const [deliviryType, setDeliviryType] = useState('auto')
    const changeWays = () => {
        const buf = countryFrom;
        setCountryFrom(countryTo);
        setCountryTo(buf);
    }

    const typeTranslate ={
        clothes: 'Одежда',
        shoes: 'Обувь',
        autoparts: 'Автозапчасти',
        consumer: 'Товвары народного потребления',
        household: 'Хоз товары'
    }

    const price = {
        auto: {
            'household': { 
                '101': 3.3,
                '111': 3.2,
                '121': 3.1,
                '131': 3,
                '141': 2.9,
                '151': 2.8,
                '161': 2.7,
                '171': 2.6,
                '181': 2.5,
                '191': 2.4,
                '201': 2.3,
                '241': 2.2,
                '261': 2.1,
                '301': 2,
                '351': 1.9,
                '401': 1.8,
                '501': 1.7,
                '601': 1.6,
                '701': 1.5,
                '800': 1.5,
                '1000': 1.5
            },

            'consumer': {
                '101': 3.7,
                '111': 3.6,
                '121': 3.5,
                '131': 3.4,
                '141': 3.3,
                '151': 3.2,
                '161': 3.1,
                '171': 3,
                '181': 2.9,
                '191': 2.8,
                '201': 2.7,
                '251': 2.6,
                '351': 2.5,
                '400': 2.4,
            },

            'clothes': {
                '101': 5.1,
                '111': 5,
                '121': 4.9,
                '131': 4.8,
                '141': 4.7,
                '151': 4.6,
                '161': 4.5,
                '171': 4.4,
                '181': 4.3,
                '191': 4.2,
                '201': 4.1,
                '251': 4,
                '301': 3.9,
                '351': 3.8,
                '400': 3.7,
            },

            'shoes': {
                '101': 4.1,
                '111': 4,
                '121': 3.9,
                '131': 3.8,
                '141': 3.7,
                '151': 3.6,
                '161': 3.5,
                '171': 3.4,
                '181': 3.3,
                '191': 3.2,
                '201': 3.1,
                '241': 3,
                '261': 2.9,
                '301': 2.8,
                '351': 2.7,
                '401': 2.6,
                '500': 2.5
            },

            'autoparts': {
                '101': 3.1,
                '111': 3,
                '121': 2.9,
                '131': 2.8,
                '141': 2.7,
                '151': 2.6,
                '161': 2.5,
                '171': 2.4,
                '181': 2.3,
                '191': 2.2,
                '201': 2.1,
                '251': 2.05,
                '301': 2,
                '351': 1.95,
                '400': 1.9,
            }
        },

        train: {
            'household': { 
                '101': 3.3,
                '111': 3.2,
                '121': 3.1,
                '131': 3,
                '141': 2.9,
                '151': 2.8,
                '161': 2.7,
                '171': 2.6,
                '181': 2.5,
                '191': 2.4,
                '201': 2.3,
                '241': 2.2,
                '261': 2.1,
                '301': 2,
                '351': 1.9,
                '401': 1.8,
                '501': 1.7,
                '601': 1.6,
                '701': 1.5,
                '800': 1.5,
                '1000': 1.5
            },

            'consumer': { 
                '101': 3.3,
                '111': 3.2,
                '121': 3.1,
                '131': 3,
                '141': 2.9,
                '151': 2.8,
                '161': 2.7,
                '171': 2.6,
                '181': 2.5,
                '191': 2.4,
                '201': 2.3,
                '241': 2.2,
                '261': 2.1,
                '301': 2,
                '351': 1.9,
                '401': 1.8,
                '501': 1.7,
                '601': 1.6,
                '701': 1.5,
                '800': 1.5,
                '1000': 1.5
            },

            'clothes': { 
                '101': 3.3,
                '111': 3.2,
                '121': 3.1,
                '131': 3,
                '141': 2.9,
                '151': 2.8,
                '161': 2.7,
                '171': 2.6,
                '181': 2.5,
                '191': 2.4,
                '201': 2.3,
                '241': 2.2,
                '261': 2.1,
                '301': 2,
                '351': 1.9,
                '401': 1.8,
                '501': 1.7,
                '601': 1.6,
                '701': 1.5,
                '800': 1.5,
                '1000': 1.5
            },

            'shoes': { 
                '101': 3.3,
                '111': 3.2,
                '121': 3.1,
                '131': 3,
                '141': 2.9,
                '151': 2.8,
                '161': 2.7,
                '171': 2.6,
                '181': 2.5,
                '191': 2.4,
                '201': 2.3,
                '241': 2.2,
                '261': 2.1,
                '301': 2,
                '351': 1.9,
                '401': 1.8,
                '501': 1.7,
                '601': 1.6,
                '701': 1.5,
                '800': 1.5,
                '1000': 1.5
            },

            'autoparts': { 
                '101': 3.3,
                '111': 3.2,
                '121': 3.1,
                '131': 3,
                '141': 2.9,
                '151': 2.8,
                '161': 2.7,
                '171': 2.6,
                '181': 2.5,
                '191': 2.4,
                '201': 2.3,
                '241': 2.2,
                '261': 2.1,
                '301': 2,
                '351': 1.9,
                '401': 1.8,
                '501': 1.7,
                '601': 1.6,
                '701': 1.5,
                '800': 1.5,
                '1000': 1.5
            }
        },
        avia: {
            'household': {
                '100': 25
            },
            'clothes': {
                '100': 25
            },
            'shoes': {
                '100': 25
            },
            'autoparts': {
                '100': 25
            },
            'consumer': {
                '100': 25
            }
        }
    }
    
    const deliviryTypeByTime = {
        auto: {
            'household': '10-15',
            'clothes': '15-18',
            'shoes': '10-15',
            'autoparts': '10-15',
            'consumer': '20-30'
        },
        train: {
            'household': '35-45',
            'clothes': '35-45',
            'shoes': '35-45',
            'autoparts': '35-45',
            'consumer': '35-45'
        },
        avia: {
            'household': '1-7',
            'clothes': '1-7',
            'shoes': '1-7',
            'autoparts': '1-7',
            'consumer': '1-7'
        }
    }

    function calcTime(type: string): string | number {
        try {
          return deliviryTypeByTime[deliviryType][type] === undefined ? '0'  : deliviryTypeByTime[deliviryType][type]; 
        } catch (error) {
          return "0";
        }
      } 

      function calcCost(type: string, weight: number, volume: number, productType: string): string | number {
        try {
            const c = weight / volume;
            const keys = Object.keys(price[type][productType]).map(Number); 
            let selectedKey;
    
            // Check if c is less than the smallest key
            if (c < keys[0]) {
                selectedKey = keys[0].toString(); 
            } else {
                for (let i = 0; i < keys.length - 1; i++) {
                    if (c >= keys[i] && c < keys[i + 1]) {
                        selectedKey = keys[i].toString();
                        break;
                    }
                }
    
                // Если c больше последнего ключа, берем последний ключ
                if (selectedKey === undefined && c >= keys[keys.length - 1]) {
                    selectedKey = keys[keys.length - 1].toString();
                }
            }
    
            return selectedKey === undefined ? '0' : parseFloat((price[type][productType][selectedKey] * weight).toFixed(1)); 
        } catch (error) {
            console.log(error)
            return "0";
        }
    }
    

    useEffect(() => {
        const calculatedTime = calcTime(productType); 
        setTime(calculatedTime); 
      }, [deliviryType, productType]); 
      
      useEffect(() => {
        const calculatedPrice = calcCost(deliviryType, weight, volume, productType); 
        setCost(calculatedPrice); 
        console.log(productType)
      }, [weight, productType, deliviryType, volume]);

      const handleWeightChange = (event) => {
        setWeight(parseFloat(event.target.value)); 
      };
      
      const handleVolumeChange = (event) => {
        setVolume(parseFloat(event.target.value)); 
      };
      
      const increment_weight = () => {
        setWeight(weight + 1); 
      };
      
      const decrement_weight = () => {
        setWeight(weight - 1);
      };
      
      const increment_volume = () => {
        setVolume(volume + 1);
      };
      
      const decrement_volume = () => {
        setVolume(volume - 1);
      }; 
      
    return (
    
            <div id="calculator" className="calc-machine-lay">
                <div className="calc-machine-way-wrap">
                    {/* <Modal_from/> */}
                    {IsModalFromOpened && <Modal_from Close={setIsModalFromOpened} Country={setCountryFrom}/>}
                    {IsModalToOpened && <Modal_to Close={setIsModalToOpened} Country={setCountryTo}/>}
                    <div className="calc-machine-way-item" onClick={() => setIsModalFromOpened(true)} >
                        <img src="/img/CalcLay/Pin_fill.png"/>
                        <span style={{cursor: 'pointer'}}>{countryFrom}</span>
                    </div>

                    <div className="calc-machine-way-item">
                        <img style={{marginLeft: '10px'}} src="\img\CalcLay\Vector 399.png"/>
                        <hr />
                    </div>

                    <div className="calc-machine-way-item" onClick={() => setIsModalToOpened(true)} Country={setCountryTo}>
                        <img src="\img\CalcLay\иконка_заглушка.png"/>
                        <span style={{cursor: 'pointer'}}>{countryTo}</span>
                    </div>

                    <img style={{cursor: 'pointer'}} src="\img\CalcLay\иконка_кнопка.png" className="calc-change-btn" onClick={changeWays}/>
                </div>
                <div className="calc-machine">
                    <div className="calc-deliviry-type">
                        <div style={{cursor: 'pointer'}} className={deliviryType === 'auto' ? "calc-deliviry-type-item-act" : "calc-deliviry-type-item"} onClick={() => setDeliviryType('auto')}>
                            <img src={deliviryType === 'auto' ? "/img/CalcLay/auto-act.svg" : "/img/CalcLay/auto-def.svg"} alt="" />
                            <span>Авто</span>
                        </div>
                        <div style={{cursor: 'pointer'}} className={deliviryType === 'avia' ? "calc-deliviry-type-item-act" : "calc-deliviry-type-item"} onClick={() => setDeliviryType('avia')}>
                            <img src={deliviryType === 'avia' ? "/img/CalcLay/avia-act.svg" : "/img/CalcLay/avia-def.svg"} alt="" />
                            <span>Авиа</span>
                        </div>
                        <div style={{cursor: 'pointer'}} className={deliviryType === 'train' ? "calc-deliviry-type-item-act" : "calc-deliviry-type-item"} onClick={() => setDeliviryType('train')}>
                            <img src={deliviryType === 'train' ? "/img/CalcLay/train-act.svg" : "/img/CalcLay/train-def.svg"} alt="" />
                            <span>ЖД</span>
                        </div>
                        
                    </div>

                    <div className="calc-parapms-layout">
                        <div className="calc-params-wrap">
                            {IsModalTypeOpened && <Type Close={setIsModalTypeOpened} Type={setProductType}/>}
                            <div className="calc-param-wrap">
                                <div className="calc-param-values-wrap">
                                    <div className="calc-param-values-wrap-names">
                                        <span style={{color: '#626C75'}}>Вес</span>
                                        <span style={{color: '#626C75'}}>кг.</span>
                                    </div>
                                    <div className="calc-param-values-wrap-names">
                                    <input 
                                        className="calc-param-values-wrap-names-text" 
                                        type="number" // Add type="number" for numeric input
                                        value={weight} 
                                        onChange={handleWeightChange}
                                    />
                                    </div>
                                </div>
                                <img src="\img\CalcLay\Vector 507.png" className="calc-divider-line" alt="" />
                                <div className="calc-params-btns">
                                    <img style={{cursor: 'pointer'}} src="\img\CalcLay\Expand_up.png" alt="" onClick={increment_weight}/>
                                    <img style={{cursor: 'pointer'}} src="\img\CalcLay\expand-down.png" alt="" onClick={decrement_weight}/>
                                </div>
                            </div>
                            <div className="calc-param-wrap">
                                <div className="calc-param-values-wrap">
                                    <div className="calc-param-values-wrap-names">
                                        <span style={{color: '#626C75'}}>Объем</span>
                                        <span style={{color: '#626C75'}}>куб.м.</span>
                                    </div>
                                    <div className="calc-param-values-wrap-names">
                                    <input 
                                        className="calc-param-values-wrap-names-text" 
                                        type="number" // Add type="number" for numeric input
                                        value={volume} 
                                        onChange={handleVolumeChange} 
                                    />
                                    </div>
                                </div>
                                <img src="\img\CalcLay\Vector 507.png" className="calc-divider-line" alt="" />
                                <div className="calc-params-btns">
                                    <img style={{cursor: 'pointer'}} src="\img\CalcLay\Expand_up.png" alt="" onClick={increment_volume}/>
                                    <img style={{cursor: 'pointer'}} src="\img\CalcLay\expand-down.png" alt="" onClick={decrement_volume}/>
                                </div>
                            </div>
                        </div>
                        <div className="calc-parap-select" onClick={() => setIsModalTypeOpened(true)}>
                            <div className="calc-param-values-wrap">
                                {productType === null ? (
                                    <>
                                        <div className="calc-param-values-wrap-names">
                                            <span style={{ color: '#626C75' }}>Категория товара</span>
                                            <span style={{ color: '#626C75' }}></span> 
                                        </div>
                                        <div className="calc-param-values-wrap-names">
                                            <span 
                                                style={{ color: '#626C75', fontSize: '130%', whiteSpace: 'nowrap', cursor: 'pointer' }} 
                                                className="calc-param-values-wrap-names-text"
                                            >
                                                Выберите из списка
                                            </span> 
                                        </div>
                                    </>
                                ) : (
                                    <span 
                                    style={{ 
                                        padding: '2% 5%', 
                                        whiteSpace: 'nowrap', 
                                        display: 'flex', 
                                        fontSize: '110%', 
                                        textAlign: 'center',
                                        width: 'fit-content' 
                                    }} 
                                        className="calc-deliviry-type-item-act"
                                    >
                                        {typeTranslate[`${productType}`]}
                                    </span>
                                )}
                            </div>
                        </div> 
                    </div>
                    <div className="calc-results">
                        <div className="calc-result-text-wrap">
                            <div className="calc-deliviry-result">
                                <div className="calc-deliviry-result-title">Сроки доставки</div>
                                <div className="calc-deliviry-result-value">
                                    <div>{time}</div>
                                    <span>дн.</span>
                                </div>
                            </div>

                            <div className="calc-deliviry-result">
                                <div className="calc-deliviry-result-title">Стоимость</div>
                                <div className="calc-deliviry-result-value">
                                    <span>от</span>
                                    <div>${cost}</div>
                                    
                                </div>
                            </div>
                        </div>
                        <div style={{cursor: 'pointer'}} onClick={() => window.location.href='https://t.me/ulamanager'} className="calc-next-action-btn">
                            <span>Получить консультацию</span>
                            <img src="\img\CalcLay\calc-next.png" alt="" />
                        </div>
                        
                    </div>
                    
                </div>
            </div>
        
    )
}

export default Calculator;