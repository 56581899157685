import React from "react";
import { useState } from "react";
import './Transportation.css'
import { Link } from "react-router-dom";

const Transportation: React.FC = () => {
    const [cart, setCart] = useState(1)
    const increment = () => {
        if (cart< 4){
            setCart(cart+1);
        }
    }

    const decrement = () => {
        if (cart > 1){
            setCart(cart-1);
        }
    }

    return (
        <>
           <div className="transportation-layout" id="transportation">
                <span className="main-title-type-1">Какие виды перевозок мы осуществляем:</span>
                {cart === 1 && 
                    <div className="transpotation-wraper">
                    <div className="transportation-grid">
                        <div className="trans-main-block">
                            <img src="\img\Transportation\Card1.png" alt="" />
                            <div className="transportation-cart-info">
                                <div className="transportation-cart-header">
                                    <div className="transportation-cart-text">
                                        <span className="transportation-cart-title">Автоперевозки</span>
                                        <span className="transportation-cart-desc">Комплексная доставка для всех отраслей:  от потребительских товаров до специализированного оборудования</span>
                                    </div>
                                    <div className="transportation-deliver-time-wrap">
                                        <div className="transportation-deliver-time-wrap-up">
                                            <img src="img\Transportation\Date_today.png" alt="" />
                                            <span>Срок доставки</span>
                                        </div>
                                        <div className="transportation-deliver-time-wrap-down">
                                            <b>10-30</b>
                                            <span>дн.</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="transportation-cart-btns">
                                    <span className="transportation-cart-btn"><span onClick={ () => {window.scrollTo({top: 0,behavior: 'smooth'})}} style={{textWrap: 'nowrap', cursor: 'pointer'}}>Перейти в калькулятор</span><img src="img/about/vector.svg"/></span>
                                    <span className="transportation-share-cart-btn"><span onClick={() => window.location.href='https://t.me/ulamanager'} style={{textWrap: 'nowrap', cursor: 'pointer'}}>Получить точный расчет</span><img src="img\Transportation\Arrow_right_light.png"/></span>
                                </div>
                                <img id="transportation-road" src="img\Transportation\Group 427319247.png" alt="" />
                            </div>
                            <img  id="transportation-road-m" src="img\Transportation\Group 427319247.png" alt="" />
                        </div>
                        <div className="trans-count-block">
                            <div className="trans-counter-wrap">
                                <b>{cart}</b>
                                <span>/4</span>
                            </div>
                            <img className="trans-counter-decriment" src="img\Transportation\Expand_left.png" alt="" onClick={() => decrement()}/>
                            <img className="trans-counter-incriment" src="img\Transportation\Expand_right.png" alt="" onClick={() => increment()}/>
                        </div>
                    </div>
                </div>
                }

                {cart === 2 && 
                    <div className="transpotation-wraper">
                    <div className="transportation-grid">
                        <div className="trans-main-block">
                            <img src="\img\Transportation\Card2.png" alt="" />
                            <div className="transportation-cart-info">
                                <div className="transportation-cart-header">
                                    <div className="transportation-cart-text">
                                        <span className="transportation-cart-title">Авиаперевозки</span>
                                        <span className="transportation-cart-desc">Комплексная доставка для всех отраслей: от потребительских товаров до специализированного оборудования</span>
                                    </div>
                                    <div className="transportation-deliver-time-wrap">
                                        <div className="transportation-deliver-time-wrap-up">
                                            <img src="img\Transportation\Date_today.png" alt="" />
                                            <span>Срок доставки</span>
                                        </div>
                                        <div className="transportation-deliver-time-wrap-down">
                                            <b>до 7</b>
                                            <span>дн.</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="transportation-cart-btns">
                                    <span className="transportation-cart-btn"><span onClick={ () => {window.scrollTo({top: 0,behavior: 'smooth'})}} style={{textWrap: 'nowrap', cursor: 'pointer'}}>Перейти в калькулятор</span><img src="img/about/vector.svg"/></span>
                                    <span className="transportation-share-cart-btn"><span onClick={() => window.location.href='https://t.me/ulamanager'} style={{textWrap: 'nowrap', cursor: 'pointer'}}>Получить точный расчет</span><img src="img\Transportation\Arrow_right_light.png"/></span>
                                </div>
                                <img id="transportation-road" src="img\Transportation\Group 427319247.png" alt="" />
                            </div>
                            <img  id="transportation-road-m" src="img\Transportation\Group 427319247.png" alt="" />
                        </div>
                        <div className="trans-count-block">
                            <div className="trans-counter-wrap">
                                <b>{cart}</b>
                                <span>/4</span>
                            </div>
                            <img className="trans-counter-decriment" src="img\Transportation\Expand_left.png" alt="" onClick={() => decrement()}/>
                            <img className="trans-counter-incriment" src="img\Transportation\Expand_right.png" alt="" onClick={() => increment()}/>
                        </div>
                    </div>
                </div>
                }

                {cart === 3 && 
                    <div className="transpotation-wraper">
                    <div className="transportation-grid">
                        <div className="trans-main-block">
                            <img src="\img\Transportation\Card3.png" alt="" />
                            <div className="transportation-cart-info">
                                <div className="transportation-cart-header">
                                    <div className="transportation-cart-text">
                                        <span className="transportation-cart-title">Железнодорожные перевозки</span>
                                        <span className="transportation-cart-desc">Комплексная доставка для всех отраслей: от потребительских товаров до специализированного оборудования</span>
                                    </div>
                                    <div className="transportation-deliver-time-wrap">
                                        <div className="transportation-deliver-time-wrap-up">
                                            <img src="img\Transportation\Date_today.png" alt="" />
                                            <span>Срок доставки</span>
                                        </div>
                                        <div className="transportation-deliver-time-wrap-down">
                                            <b>35-45</b>
                                            <span>дн.</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="transportation-cart-btns">
                                    <span className="transportation-cart-btn"><span onClick={ () => {window.scrollTo({top: 0,behavior: 'smooth'})}} style={{textWrap: 'nowrap', cursor: 'pointer'}}>Перейти в калькулятор</span><img src="img/about/vector.svg"/></span>
                                    <span className="transportation-share-cart-btn"><span onClick={() => window.location.href='https://t.me/ulamanager'} style={{textWrap: 'nowrap', cursor: 'pointer'}}>Получить точный расчет</span><img src="img\Transportation\Arrow_right_light.png"/></span>
                                </div>
                                <img id="transportation-road" src="img\Transportation\Group 427319247.png" alt="" />
                            </div>
                            <img  id="transportation-road-m" src="img\Transportation\Group 427319247.png" alt="" />
                        </div>
                        <div className="trans-count-block">
                            <div className="trans-counter-wrap">
                                <b>{cart}</b>
                                <span>/4</span>
                            </div>
                            <img className="trans-counter-decriment" src="img\Transportation\Expand_left.png" alt="" onClick={() => decrement()}/>
                            <img className="trans-counter-incriment" src="img\Transportation\Expand_right.png" alt="" onClick={() => increment()}/>
                        </div>
                    </div>
                </div>
                }

                {cart === 4 && 
                    <div className="transpotation-wraper">
                    <div className="transportation-grid">
                        <div className="trans-main-block">
                            <img src="\img\Transportation\Card4.png" alt="" />
                            <div className="transportation-cart-info">
                                <div className="transportation-cart-header">
                                    <div className="transportation-cart-text">
                                        <span className="transportation-cart-title">Перевозки морем</span>
                                        <span className="transportation-cart-desc">Комплексная доставка для всех отраслей:  от потребительских товаров до специализированного оборудования</span>
                                    </div>
                                    <div className="transportation-deliver-time-wrap">
                                        <div className="transportation-deliver-time-wrap-up">
                                            <img src="img\Transportation\Date_today.png" alt="" />
                                            <span>Срок доставки</span>
                                        </div>
                                        <div className="transportation-deliver-time-wrap-down">
                                            <b>35-45</b>
                                            <span>дн.</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="transportation-cart-btns">
                                    <span className="transportation-cart-btn"><span onClick={() => {window.scrollTo({top: 0,behavior: 'smooth'})}} style={{textWrap: 'nowrap', cursor: 'pointer'}}>Перейти в калькулятор</span><img src="img/about/vector.svg"/></span>
                                    <span className="transportation-share-cart-btn"><span onClick={() => window.location.href='https://t.me/ulamanager'} style={{textWrap: 'nowrap', cursor: 'pointer'}}>Получить точный расчет</span><img src="img\Transportation\Arrow_right_light.png"/></span>
                                </div>
                                <img id="transportation-road" src="img\Transportation\Group 427319247.png" alt="" />
                            </div>
                            <img  id="transportation-road-m" src="img\Transportation\Group 427319247.png" alt="" />
                        </div>
                        <div className="trans-count-block">
                            <div className="trans-counter-wrap">
                                <b>{cart}</b>
                                <span>/4</span>
                            </div>
                            <img className="trans-counter-decriment" src="img\Transportation\Expand_left.png" alt="" onClick={() => decrement()}/>
                            <img className="trans-counter-incriment" src="img\Transportation\Expand_right.png" alt="" onClick={() => increment()}/>
                        </div>
                    </div>
                </div>
                }
            </div>
            
        </>
    );
}

export default Transportation;