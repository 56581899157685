import React from "react";
import './WhyWe.css'

const WhyWe: React.FC = () => {
    

    return (
        <>
            <span className="why-we-title">Доставка грузов и оплата товаров из Китая и Южной Кореи</span>

            <iframe width="560" height="315" src="https://www.youtube.com/embed/0LoMXHJhZlg" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>

            <div className="why-we-block-wrap" style={{backgroundImage: 'url("/img/WhyWe/BlockBack.png")'}}>
                <span className="why-we-block-title">
                    Платежи в Китай и Южную Корею <br></br>
                    без задержек и переплат 
                </span>
                <span className="why-we-block-desc"> 
                    Оплачивайте счета поставщикам и выкупайте товар с китайских и корейских <br></br>
                    маркетплейсов прямо со своего расчетного счета ИП или ООО. Вы также можете <br></br>
                    оплатить товар наличными или другими доступными методами. 
                </span>

                <div style={{cursor: 'pointer'}} className="why-we-block-btn-wrap" onClick={() => window.location.href='https://t.me/ulamanager'}>
                    <span>Оплатить счет</span>
                    <img src="img\WhyWe\Arrow_right_light.png"/>
                </div>
            </div>
        </>
    );
}

export default WhyWe;