import React from "react";
import "./NavBar.css";

const NavBar: React.FC = ({popup }) => {
    return (
        <div className="navbar">
            <ul className="nav-list">
                <img src="/img/navBar/logo.svg" alt="Description of image" />
                <li><a href="#about-us" style={{color: 'white'}}>О нас</a></li>
                <li><a href="#about-us" style={{color: 'white'}}>Услуги</a></li>
                <li><a href="#calculator" style={{color: 'white'}}>Калькулятор</a></li>
                <li><a href="#footer" style={{color: 'white'}}>Контакты</a></li>
                {/* <li>
                    <ul className="sub-list">
                        <li>Доп. информация</li>
                    </ul>
                </li> */}
            </ul>
            <div className="nav-contacts">
                <div style={{alignItems: 'center', display: 'flex'}}>
                    <img src="/img/NavBar/circle.svg" alt="" />
                    <span style={{textWrap: 'nowrap'}}>+79153843405</span>
                </div>
                
                <span id="recall" style={{cursor: 'pointer'}} onClick={() => popup(true)}>Перезвонить</span>
            </div>
        </div>

    );
}

export default NavBar;
